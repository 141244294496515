<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()"> <ion-icon name="close"></ion-icon> </ion-button>
    </ion-buttons>
    <ion-title> Create conversation </ion-title>
    <ion-buttons slot="end" *ngIf="linkType">
      <ion-button
        color="dark"
        fill="outline"
        class="btn-small button-dark-border"
        style="--border-radius: 6px"
        [disabled]="true"
      >
        <ion-icon [name]="getLinkageIcon()" style="margin-right: 5px"></ion-icon>
        {{ getLinkageLabel() }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-button fill="clear" color="dark" (click)="showPrivacyOptions()" style="margin: 10px 10px 0 10px">
    <ion-icon
      [name]="type === 'email' ? 'mail-outline' : 'lock-closed-outline'"
      class="padded-icon button-icon"
    ></ion-icon>
    {{ type === 'email' ? 'Email' : 'Private' }}
    <ion-icon name="chevron-down-outline" class="button-icon icon-padded-left"></ion-icon>
  </ion-button>
  <div style="padding: 10px 20px 20px 20px">
    <div *ngIf="type === 'email'">
      <ion-label> Title </ion-label>
      <ion-input placeholder="e.g. Password reset" [(ngModel)]="title" class="input-field"> </ion-input>
    </div>

    <ion-label> Issue details <ion-text color="warning">(Required)</ion-text></ion-label>
    <ion-textarea
      #markdownInput
      placeholder="e.g. Customer needs help resetting password"
      [(ngModel)]="markdown"
      class="input-field"
    >
    </ion-textarea>

    <div style="margin: 10px 0 5px 0" *ngIf="type === 'email'">
      <ion-label>
        Linked to Email Inbox <ion-text *ngIf="type === 'email'" color="warning">(Required)</ion-text></ion-label
      >
      <br />
      <ion-spinner
        name="crescent"
        *ngIf="loadingInboxes"
        style="height: 33px; margin-top: 6px; margin-left: 5px"
      ></ion-spinner>
      <ion-button
        fill="clear"
        color="dark"
        (click)="showInboxOptions()"
        *ngIf="selectedEmailInbox"
        class="button-dark-border"
      >
        <ion-icon name="mail" class="padded-icon button-icon"></ion-icon>
        {{ selectedEmailInbox.email }}
        <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
      </ion-button>
      <ion-button
        fill="clear"
        color="danger"
        (click)="showInboxOptions()"
        *ngIf="!loadingInboxes && emailInboxes && !emailInboxes.length"
      >
        <ion-icon name="alert-circle-outline" class="padded-icon"></ion-icon>
        Configure email inbox first
      </ion-button>
    </div>

    <div style="margin: 10px 0 5px 0" [hidden]="type === 'email'">
      <ion-label> Triage Channel <ion-text color="warning">(Required)</ion-text></ion-label>
      <app-channel-selector
        [channel]="triageChannel"
        (channelDidChange)="onTriageChannelChanged($event)"
        [defaultInternalOnly]="true"
      ></app-channel-selector>
    </div>

    <div>
      <div>
        <ion-label>
          Submitter <span [hidden]="type === 'email'">(Optional)</span>
          <ion-text *ngIf="type === 'email'" color="warning">(Required)</ion-text>
        </ion-label>
        <br />
        <ion-button fill="clear" color="dark" (click)="selectSubmitter()" class="button-dark-border">
          <ion-icon
            [name]="submitter ? 'paper-plane' : 'paper-plane-outline'"
            class="padded-icon button-icon"
          ></ion-icon>
          {{
            submitter?.name
              ? submitter.name + ' (' + submitter.email + ')'
              : submitter?.email ?? 'Select a submitter...'
          }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
        </ion-button>
      </div>
      <div style="margin-top: 8px">
        <ion-label> Customer (Optional) </ion-label>
        <br />
        <ion-button fill="clear" color="dark" (click)="selectCustomer()" class="button-dark-border">
          <ion-icon [name]="customer ? 'business' : 'business-outline'" class="padded-icon button-icon"></ion-icon>
          {{ customer?.name ?? 'Select a customer...' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
        </ion-button>
      </div>
      <div style="margin-top: 8px">
        <ion-label> Assignee (Optional) </ion-label>
        <br />
        <ion-button fill="clear" color="dark" (click)="selectAssignee()" class="button-dark-border">
          <ion-icon [name]="assignee ? 'person' : 'person-outline'" class="padded-icon button-icon"></ion-icon>
          {{ assignee?.name ?? 'Select an assignee...' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
        </ion-button>
        <ion-button fill="clear" color="dark" (click)="assignToMe()" [hidden]="assignee?.id"> Assign to me </ion-button>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer>
  <div style="padding: 20px">
    <ion-button (click)="submit()" [disabled]="isLoading || loadingInboxes">
      Save and view
      <ion-spinner name="crescent" [hidden]="!isLoading" style="margin-left: 10px"></ion-spinner>
      <ion-icon name="arrow-forward-outline" class="icon-padded-left button-icon" [hidden]="isLoading"></ion-icon>
    </ion-button>
  </div>
</ion-footer>
